<template>
    <div class="container">
        <div class="right_cont">
            <!-- <div class="title flex_center_start_box">
                <div class="title_txt flex_center">
                    <img src="../../assets/img/detail/title_ic4.png" />
                    HISTORY AND EVENTS
                </div>
            </div> -->
            <div class="his_box">
          
                <div class="tbl_search_box" v-if="tableList.length > 0">
                  <div class="head-title" v-if="tableList.length > 0">Relationships</div>
                    <div class="tbl_search">
                      
                        <el-input v-model="searchTblName" type="text" clearable suffix-icon="el-icon-search" placeholder="Please enter name" @change="searchPersonByName()" />
                    </div>
                </div>
                <div class="persion-table" v-if="tableList.length > 0">
                    <el-table ref="treeTable" :data="Relationstable" border row-key="aaa_id" style="width: 100%" >
     

                        <el-table-column label="Name" prop="NAME" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                <div v-if="scope.row.type == 'Person'" style="cursor: pointer; color: #1290c9" @click="sediddetail(scope.row)">{{ scope.row.name_detail.surname }},{{ scope.row.name_detail.first_name }}</div>
                                <div style="cursor: pointer; color: #1290c9" v-else @click="sediddetail(scope.row)">
                                    {{ scope.row.name_detail.name }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Type" prop="type" align="left" show-overflow-tooltip :resizable="false" />

                        <el-table-column label="Relation" prop="connection_type" align="left" show-overflow-tooltip :resizable="false" />
                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <div style="text-align: left">No Date</div>
                        </template>
                    </el-table>
                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page.total" @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>
                        <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { searchDetailsById } from '@/api/searchApi.js';
import { getCompanyDetailMenuList, ordersAdd } from '../../api/companyApi';


import Page from '@/components/page';

export default {
    components: { Page },
    data() {
        return {
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            activities: [],
            Relationstable: [],
            tableList:'',
            searchTblName:'',
            parentTable1:[]
        };
    },
    computed: {
        headerCellStyle() {
            return {
                backgroundColor: 'red', // 设置背景色
                // 其他样式属性
            };
        },
    },

    created() {
        this.getdetail();
    },
    methods: {
        pageList() {
            // console.log(this.parentTable);

            this.Relationstable = this.parentTable1.filter((item, index) => index < this.page.pageNo * this.page.pageSize && index >= this.page.pageSize * (this.page.pageNo - 1));
            this.page.total = this.parentTable1.length;
        },

        onPageChangeList(pageNo) {
            // this.pageNo += 1
            this.page.pageNo = pageNo;
            this.pageList();
        },
        currtentPageChange(pageSize) {
            // this.pageNo += 1
            this.page.pageSize = pageSize;
            // this.init()
            this.pageList();
        },
         // 人名筛选
         searchPersonByName() {
            let list = this.tableList;
            let keyWord = this.searchTblName;
            var arr = [];
            if (keyWord == '') {
                this.Relationstable = list;
                this.parentTable1 = list;
            } else {
                // console.log(`list`, list);
                console.log(keyWord);
                let that = this;
                arr = list.filter(item => {
                    console.log(`item`, item);
                    if (item.type == 'Person') {
                        return item?.name_detail?.surname?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1 || item?.name_detail?.first_name?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                    } else {
                        return item?.name_detail?.name?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                    }
                });
                console.log(arr);
                this.parentTable1 = arr;
            }
            this.pageList();
        },
        getdetail() {
            searchDetailsById({ id: this.$route.query.id }).then(res => {
      
                // this.Relationstable=
                // this.page.total=this.Relationstable.length


        
                this.parentTable1 = res.data.attributes?.relationship?.connection_details ? res.data.attributes.relationship.connection_details : [];
                this.tableList = res.data.attributes.relationship?.connection_details ? res.data.attributes.relationship?.connection_details : [];
                this.pageList();
            });
        },
      async  sediddetail(row) {
            console.log(`row`, row);
            let nmae = '';
            if (row.type == 'Person') {
                nmae = row.name_detail.surname + ',' + row.name_detail.first_name;
            } else {
                nmae = row.name_detail.name;
            }

            let params = 'code=' + row.profile_id + '&type=' + 11;
            let res = await getCompanyDetailMenuList(params);
            if (res && res.code) {
                let power = res.data[0].power;
                if (power) {
                    let routeData = this.$router.resolve({
                        path: '/sanctions/companysnapshot?type=' + row.type + '&id=' + row.profile_id + '&name=' + nmae + '&information=Search Sanctions',
                        // path: '/search/newsanction?' + '&type=' + row.attributes.type + '&id=' + row.id + '&gender=' + row.attributes.gender + '&subsidiary=' + row.attributes.is_subsidiary + '&name=' + row.attributes.primary_name,
                    });

                    window.open(routeData.href, '_blank');
                } else {
                    this.getOrdersAdd(row,nmae).then(res => {
                        if (res) {
                            let routeData = this.$router.resolve({
                                path: '/sanctions/companysnapshot?type=' + row.type + '&id=' + row.profile_id + '&name=' + nmae + '&information=Search Sanctions',
                                // path: '/search/newsanction?' + '&type=' + row.attributes.type + '&id=' + row.id + '&gender=' + row.attributes.gender + '&subsidiary=' + row.attributes.is_subsidiary + '&name=' + row.attributes.primary_name,
                            });

                            window.open(routeData.href, '_blank');
                        }
                    });
                }
            }
     
        },
        getOrdersAdd(row,name) {
            const type = 20;
             console.log(row);
            //1  :线上报告
            let params = {
                companyName: name, //"贸易产品描述",
                aaaId: row.profile_id, //"贸易产品描述code",
                companyCountry: row.attributes?.country_territory_name, // 国家二位编码
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            return new Promise((resolve, reject) => {
                ordersAdd(params).then(result => {
                    let data = result;
                    if (data && data.code) {
                        setTimeout(() => {
                            resolve(true);
                        }, 1500);
                       
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            // duration: 5000,
                        });
                        let that = this;
                        let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

                        console.log(`userinfo`, userinfo);
                        let routeData = '';
                        setTimeout(function () {
                            if (userinfo.type == '3') {
                                routeData = that.$router.resolve({
                                    path: '/account/quota',
                                });
                            } else {
                                routeData = that.$router.resolve({
                                    path: '/account/addCredit',
                                });
                            }

                            window.open(routeData.href, '_blank');
                        }, 1000);

                        resolve(false);
                    }
                });
            });
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.right_cont {
    margin-top: 60px;
}
.title {
    margin-bottom: 20px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.pageBox {
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.his_box {
    width: 100%;
    padding: 30px 30px 30px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px 14px 0px 0px;
}
.his_box /deep/ .el-timeline-item__wrapper {
    display: flex;
    padding-left: 14px;
}
.his_box /deep/ .el-timeline-item__node--normal {
    width: 8px;
    height: 8px;
    left: 1px;
}
.his_box /deep/ .el-timeline-item__node {
    background-color: #ff7600;
}
.his_box /deep/ .el-timeline-item__tail {
    border-left: 2px dashed #8497ab;
}
.his_box /deep/ .el-timeline-item__timestamp.is-top {
    font-size: 16px;
    color: #8497ab;
    padding-top: 0;
}
.his_box /deep/ .el-timeline-item__content {
    padding-bottom: 24px;
    font-size: 16px;
    color: #022955;
    border-bottom: 1px solid #e7eaed;
    margin-left: 20px;
    flex: 1;
    line-height: 26px;
    margin-top: -5px;
}
.head-title {
    /* color: #022955; */
    font-size: 16px;
    line-height: 35px;
    /* margin-top: 20px; */
    color: #ff7600;
    /* padding-left: 20px; */
    font-weight: 700;
    font-family: 'element-icons';
}
.his_box /deep/ .el-table th.el-table__cell {
    background: #f2f5f9;
}
.tbl_search_box {
    /* position: absolute;
  left: 0; */
  display: flex;
    justify-content: space-between;
   margin-bottom: 20px;
}
.title_txt-right {
    display: flex;
}

.tbl_search {
    position: relative;
    width: 290px;
    height: 40px;
    z-index: 9;
}
.tbl_search /deep/ .el-input .el-input__inner {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
    border-radius: 8px;
    border: 1px solid #1290c9;
    padding: 0 40px 0 20px;
}
.tbl_search .el-input {
    height: 100%;
}
</style>
